<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{ $t('installment.installment_agreements')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
<!--                    <router-link to="/installment/installment" class="btn btn-light font-weight-bolder">-->
<!--                        <v-icon>mdi-chevron-left</v-icon>-->
<!--                        {{ $t('back') }}-->
<!--                    </router-link>-->
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                    <b-tabs content-class="mt-3">
                        <b-tab :title="$t('activity_log.basic_information')" active>
                            <br>
                            <div>{{$t('installment.client')}}: {{data.client_name}} #{{data.client_id}}</div>
                            <div class="mt-5">
                                <table class="table text-center border-secondary">
                                    <thead>
                                        <tr>
                                            <th>{{$t('installment.installment_agreement_amount')}}</th>
                                            <th>{{$t('installment.invoice')}}</th>
                                            <th>{{$t('status')}}</th>
                                            <th>{{$t('installment.installment_account')}}</th>
                                            <th>{{$t('installment.installment_amount')}}</th>
                                            <th>{{$t('installment.paid_amount')}}</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{data.invoice_total}}</td>
                                            <td>#{{data.invoice_id}}</td>
                                            <td>{{data.status_name}}</td>
                                            <td>{{data.installment_account}}</td>
                                            <td>{{data.installment_amount}} {{data.currency_name}} /{{ data.period_installment_name}}</td>
                                            <td>{{data.paid_amount}}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="mt-10">
                                <table class="table text-center border-secondary">
                                    <thead>
                                    <tr>
                                        <th>{{$t('installment.installment_start_date')}}</th>
                                        <th>{{$t('installment.due_date')}}</th>
                                    </tr>

                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{{data.installment_start_date}}</td>
                                        <td>{{data.installment_due_date}}</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="mt-10">
                                <h5 class="mt-5">{{$t('installment.installments_list')}}</h5>
                                <table class="table table-row-bordered border-secondary">
                                    <thead>
                                    <tr>
                                        <th>{{$t('installment.id_serial_no')}}</th>
                                        <th>{{$t('installment.client_info')}}</th>
                                        <th>{{$t('installment.installment_amount')}}</th>
                                        <th>{{$t('installment.due_date')}}</th>
                                        <th>{{$t('status')}}</th>
                                        <th>{{$t('actions')}}</th>
                                    </tr>

                                    </thead>
                                    <tbody>
                                    <tr v-for="(row, index) in data.details" :key="index">
                                        <td>{{row.id}}</td>
                                        <td>
                                            <p>{{$t('installment.client_no')}}: {{ row.client_id}}</p>
                                            <p>{{$t('installment.client_name')}}: {{ row.client_name}}</p>
                                        </td>
                                        <td>{{row.installment_amount_per_one}} {{data.currency_name}}</td>
                                        <td>{{row.installment_due_date}}</td>
                                        <td>{{row.status_name}}</td>
                                        <td>
                                            <v-btn icon color="pink" :to="`/installment/payments/view/${row.id}`">
                                                <v-icon small class="mr-2">mdi-eye</v-icon>
                                            </v-btn>

                                            <v-btn color="pink" v-if="$can('installments.pay')" :to="`/installment/installment/pay/${row.id}/${id}`">
                                                {{$t('installment.pay')}}
                                            </v-btn>
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>
                            </div>


                        </b-tab>
                        <b-tab :title="$t('activity_log.activity_log')">
                            <br>
                            <activity-log v-if="id" :inner-key="key" :id="id"></activity-log>
                        </b-tab>
                    </b-tabs>
                </div>
        </div>
        <!--end::customer-->
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ActivityLog from "@/view/content/widgets/details/ActivityLog";

    export default {
        name: "view-installment-details",
        components:{'activity-log': ActivityLog},
        data() {
            return {
                mainRoute: 'installmentmanagement/installments',
                id: this.$route.params.id? this.$route.params.id : null,
                key: 'Installment',
                data: {},
            };
        },

        methods: {
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
                    this.data = response.data.data;
                });
            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.installment"), route:'/installment/installment'}, {title: this.$t('view')}]);
            if (this.id) {
                this.getData();
            }

        },
    };
</script>